module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://ddtsi.com"},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-17734205-1","head":true,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"DDTSI","short_name":"DDTSI","description":"Long-haul transportation services specializing in Driving Dependable Service.","start_url":"/","background_color":"#f7f6f5","theme_color":"#2A2927","display":"minimal-ui","icon":"static/logos/logo-512.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0427084b060c95915492763b887323d8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#2A2927"},
    },{
      plugin: require('../node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[],"modalProps":{"style":{"overlay":{"position":"fixed","top":0,"left":0,"right":0,"bottom":0,"backgroundColor":"rgba(0, 0, 0, 0.75)"},"content":{"position":"absolute","border":"none","background":"none","padding":0,"paddingTop":48,"top":0,"bottom":0,"right":0,"left":0,"overflow":"auto","WebkitOverflowScrolling":"touch"}},"contentLabel":"Modal"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
